.contactme-container {
  background-color: #111;
  color: white;
  padding: 60px 20px;
  font-family: "Poppins", Arial, sans-serif;
}

.contactme-header {
  color: white;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 200px;
}

.contactme-title {
  color: #50c878;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 40px;
}

.contactme-content {
  background-color: #222;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

.contactme-info,
.contactme-form {
  padding: 40px;
  flex: 1;
}

.contactme-info h2,
.contactme-form h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.contactme-info p {
  margin-bottom: 30px;
  line-height: 1.6;
}

.contactme-details {
  margin-bottom: 30px;
}

.contactme-item {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  word-break: break-word;
}

.contactme-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.contactme-social-links {
  display: flex;
  gap: 15px;
}

.contactme-social-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #32cd32;
  border-radius: 50%;
}

.contactme-social-icon img {
  width: 20px;
  height: 20px;
}

.contactme-form form {
  display: flex;
  flex-direction: column;
}

.contactme-form input,
.contactme-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  color: white;
}

.contactme-form textarea {
  height: 150px;
  resize: vertical;
}

.contactme-form button {
  background-color: #32cd32;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
}

.contactme-copyright {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #444;
  font-size: 0.9rem;
  color: #888;
}

@media (max-width: 768px) {
  .contactme-content {
    flex-direction: column;
  }

  .contactme-header {
    margin-top: 100px;
  }

  .contactme-item {
    font-size: 0.9rem;
    align-items: flex-start;
  }

  .contactme-info,
  .contactme-form {
    padding: 20px;
  }

  .contactme-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

@media (max-width: 320px) {
  .contactme-item span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
}
