/* General styles */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

body {
  background-color: #101010;
  color: white;
  margin: 0;
  padding: 0;
  font-family: "Poppins", Arial, sans-serif;
}

.App {
  text-align: center;
}

/* Navbar styles */
nav {
  background-color: #1a1a1a;
  padding: 1rem 0;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav .navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-items-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
}

.nav-icons-container {
  display: flex;
  gap: 1rem;
}

nav li {
  margin: 0 1rem;
}

nav a {
  color: white;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #32cd32;
}

.nav-icon {
  width: 32px;
  height: 32px;
  transition: transform 0.3s ease;
}

.nav-icon:hover {
  transform: scale(1.1);
}

/* Intro section */
.intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center the content horizontally */
  gap: 30px;
  margin-top: 3rem;
  margin-bottom: 500px; /* Add more space below the intro section */
  height: 80vh; /* Ensure it takes up most of the viewport height */
}

.intro-text {
  text-align: center;
  font-size: calc(16px + 3vmin);
}

.typewriter-container {
  font-size: calc(14px + 2vmin);
  color: #fff;
}

.highlight {
  color: #50c878;
}

.profile-pic {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.intro-paragraph {
  font-size: 1.8rem;
  line-height: 1.8;
  font-weight: 600;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 100px;
}

/* About section */
.about-container {
  margin-top: 50px; /* Adjust this value to bring the content higher */
  padding: 0 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.about-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.about-header {
  font-size: 2.5rem;
  margin-bottom: 20px;
  margin-top: -350px; /* Adjust this value to bring the header higher */
  color: #50c878;
}

.about-paragraph {
  font-size: 1.5rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Contact section */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
  padding: 0 20px;
}

.contact-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.contact-item {
  display: flex;
  flex-direction: column; /* Stack text below icons */
  align-items: center;
  gap: 0.5rem; /* Space between icon and text */
}

.contact-icon {
  width: 32px;
  height: 32px;
}

.contact-item h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center; /* Center text */
}

.contact-item p {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center; /* Center text */
}

.resume-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px; /* Add more space below the resume button */
}

/* Technical skills section */
.technical-skills-container {
  margin-top: 100px; /* Add more space above the technical skills section */
  padding: 0 20px;
}

.technical-skills-header {
  font-size: 2.5rem;
  color: #50c878;
}

/* Custom button styles */
.custom-button {
  background-color: #333;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.custom-button:hover {
  background-color: #555;
  transform: scale(1.05);
}

.resume-button {
  background-color: #333;
}

.resume-button:hover {
  background-color: #333;
}

.front-end {
  margin-right: auto;
}

.back-end {
  margin-left: auto;
}

.front-end,
.back-end,
.full-stack {
  background-color: #333;
}

.front-end:hover,
.back-end:hover,
.full-stack:hover {
  background-color: #555;
}

/* Media queries */
@media (max-width: 1024px) {
  nav a {
    font-size: 1.1rem;
  }

  .nav-icon {
    width: 28px;
    height: 28px;
  }

  .intro-text {
    font-size: calc(14px + 2vmin);
  }

  .intro-paragraph {
    font-size: 1.6rem;
    margin-bottom: 80px;
  }

  .profile-pic {
    width: 220px;
    height: 220px;
  }

  .custom-button {
    font-size: 1.1rem;
    padding: 12px 25px;
  }

  .about-header {
    font-size: 2rem;
  }

  .about-paragraph {
    font-size: 1.3rem;
  }

  .contact-item h3,
  .contact-item p {
    font-size: 1rem;
  }

  .contact-icon {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 768px) {
  nav {
    padding: 0.5rem 0;
  }

  nav ul {
    flex-direction: column;
  }

  .nav-items-container,
  .nav-icons-container {
    justify-content: center;
  }

  .nav-items-container {
    margin-bottom: 0.5rem;
  }

  nav a {
    font-size: 1rem;
    margin: 0.5rem 0;
  }

  .nav-icon {
    width: 24px;
    height: 24px;
  }

  .intro-container {
    flex-direction: column;
    align-items: center;
  }

  .intro-text {
    font-size: calc(12px + 2vmin);
  }

  .typewriter-container {
    font-size: calc(12px + 2vmin);
  }

  .intro-paragraph {
    font-size: 1.4rem;
    margin-bottom: 60px;
  }

  .profile-pic {
    width: 200px;
    height: 200px;
  }

  .custom-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .about-header {
    font-size: 1.8rem;
  }

  .about-paragraph {
    font-size: 1.2rem;
  }

  .contact-container {
    flex-direction: column;
  }

  .contact-items {
    flex-direction: column; /* Stack contact items vertically */
    gap: 1rem;
  }

  .contact-item {
    align-items: center;
    gap: 1rem;
  }

  .contact-item h3,
  .contact-item p {
    font-size: 0.9rem;
  }

  .contact-icon {
    width: 24px;
    height: 24px;
  }

  .resume-button-container {
    margin-bottom: 100px; /* Add more space for smaller screens */
  }
}
