.home-button {
  background-color: #282c34;
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.home-button:hover {
  background-color: #61dafb;
}
