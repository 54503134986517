.projects-container {
  padding: 50px 20px;
  text-align: center;
}

.projects-header {
  font-size: 2.5rem;
  color: #50c878;
  margin-bottom: 10px;
  margin-top: 200px;
}

.projects-subheader {
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 40px;
}

.projects-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.project-card {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  transform: translateX(100%);
  animation: fadeInRight 1s forwards;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
}

.project-image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.project-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-card:hover .project-image {
  transform: scale(1.1);
}

.github-link {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  padding: 5px;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.project-card:hover .github-link {
  opacity: 1;
}

.github-icon {
  width: 30px;
  height: 30px;
}

.project-title {
  font-size: 1.5rem;
  color: #32cd32;
  margin: 10px 0;
}

.project-description {
  font-size: 1rem;
  color: #fff;
}

@keyframes fadeInRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
