.work-experience-container {
  margin-top: 120px;
  padding: 0 40px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.work-experience-header {
  font-size: 3rem;
  font-weight: 700;
  color: #50c878;
  margin-top: 350px;
  margin-bottom: 60px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.work-experience-item {
  background-color: #1a1a1a;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0; /* Start with 0 opacity for fade-in effect */
  transform: translateY(20px); /* Start slightly below for fade-up effect */
}

.work-experience-item.aos-animate {
  opacity: 1;
  transform: translateY(0);
}

/* Rest of your CSS remains the same */

.work-experience-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
}

.work-experience-dates {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
  text-align: center;
}

.company-header {
  display: flex;
  flex-direction: row; /* Change to row for horizontal layout */
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.company-logo {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  object-fit: contain;
}

.company-header h3 {
  font-size: 2rem;
  font-weight: 600;
  color: #32cd32;
  margin: 0;
  font-weight: 600;
}

.work-experience-item p {
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: underline;
  color: white;
  margin-bottom: 15px;
  line-height: 1.6;
  text-align: center;
}

.work-experience-item ul {
  list-style-type: none;
  padding-left: 0;
}

.work-experience-item li {
  font-size: 1rem;
  font-weight: 600;
  color: #fffff9;
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
}

.work-experience-item li::before {
  content: "•";
  color: #32cd32;
  font-size: 1.2em;
  position: absolute;
  left: 0;
  top: -2px;
}

@media (max-width: 768px) {
  .work-experience-container {
    padding: 0 20px;
  }

  .work-experience-header {
    font-size: 2.5rem;
    font-weight: 600;
  }

  .company-header h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .work-experience-item p {
    font-size: 1rem;
    font-weight: 600;
  }

  .company-logo {
    width: 30px;
    height: 30px;
  }
}
